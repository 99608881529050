import video from "./video.mp4";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <video
          // className="App-logo"
          // controls
          Autoplay={"autoplay"}
          loop
          muted
          style={{
            height: "40vh",
            borderRadius: "80px",
            padding: "20px",
            objectFit: "cover",
            marginBottom: "15px",
            position: "relative",
          }}
        >
          <source src={video} type="video/mp4" style={{}} />
        </video>

        <p
          style={{
            fontSize: "28px",
            paddingLeft: "15%",
            paddingRight: "15%",
            color: "#000",
            fontFamily: "'Poppins', sans-serif",
          }}
        >
          <h1
            style={{
              color: "#fff",
              fontFamily: "'Oswald', sans-serif",
            }}
          >
            A new way to see{" "}
            <a href="https://en.wikipedia.org/wiki/Non-fungible_token">NFT</a>
          </h1>
          <b style={{ color: "#4a06ff" }}>90%</b> of NFT are PFPs which are most
          commonly known as “Profile Pictures”. Sadly, only{" "}
          <b style={{ color: "#fffc00" }}>5%</b> from the NFT world are{" "}
          <code>"collectors"</code> and the remaining{" "}
          <b style={{ color: "#fff" }}>95%</b> are{" "}
          <b style={{ fontStyle: "italic" }}>“Flippers” &amp; “Newbies”</b>. Our
          vision is to change the perspective of the people in the world of NFT
          by evolving it into a channel of{" "}
          <b style={{ textTransform: "uppercase" }}>Shareholders.</b>
        </p>
      </header>
    </div>
  );
}

export default App;
